import { PageHeading } from 'components/PageHeading'
import { Breadcrumb } from 'components/Breadcrumb'
import { Tabs } from 'components/Tabs'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { Alert } from 'components/Alert'
import { Svg } from 'components/Svg'
import { DescriptionList } from 'components/DescriptionList'
import { getVenue, getVenueActivity, getContacts, getDepartments } from 'api/venues'
import { Avatar } from 'components/Avatar'
import { PastActivityBar } from 'components/PastActivityBar'
import emailIcon from 'assets/icons/solid/mail.svg'
import phoneIcon from 'assets/icons/solid/phone.svg'
import globeIcon from 'assets/icons/solid/globe.svg'
import locationIcon from 'assets/icons/solid/location-marker.svg'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import addIcon from 'assets/icons/outline/plus.svg'
import mergeIcon from 'assets/icons/outline/share.svg'
import deleteIcon from 'assets/icons/outline/x.svg'

const ContactCard = () => {
  return {
    view ({ attrs: { venue, contact } }) {
      const fullName = `${contact.firstName || ''} ${contact.lastName || ''}`
      return m(Card, {
        buttons: [
          m(Button, {
            size: 'sm',
            classes: ['my-2', 'whitespace-nowrap', 'text-white'],
            type: 'button',
            onclick: () => {
              m.route.set(`/venues/${venue.id}/contacts/${contact.id}`)
            }
          }, [m(Svg, {
            classes: ['w-4', 'mx-auto']
          }, [editIcon]), 'Edit'])
        ]
      }, [
        m('.flex.items-center.justify-between', [
          m('div', [
            m('p', fullName),
            m('a.text-sm.text-gray-600', { href: `mailto:${contact.email}` }, contact.email || 'No email'),
            m('p.text-sm.text-gray-600', contact.telephone || 'No telephone'),
            contact.position && m('p.text-sm.text-gray-600', contact.position)
          ]),
          m(Avatar, { name: fullName })
        ])
      ])
    }
  }
}

const DepartmentCard = () => {
  return {
    view ({ attrs: { venue, department } }) {
      return m(Card, {
        onclick: () => {
          m.route.set(`/venues/${venue.id}/departments/${department.departmentId}/${department.id}`)
        },
        classes: ['cursor-pointer']
      }, [
        m('.flex.items-center.justify-between', [
          m('div', [
            m('p', department.departmentName),
            m('p.text-sm.text-gray-600', `Commission: ${(department.commission * 100).toFixed(2)} %`),
            venue.isAccommodation && m('p.text-sm.text-gray-600.mt-2', [
              'Accommodation: ',
              m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                class: department.accommodationVisibilityName === 'Public' ? 'bg-green-200 text-green-800' : department.accommodationVisibilityName === 'Closed' ? 'bg-red-200 text-red-800' : 'bg-orange-200 text-orange-800'
              },
              department.accommodationVisibilityName
              )
            ]),
            venue.isMeetings && m('p.text-sm.text-gray-600.mt-2', [
              'Meetings: ',
              m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                class: department.meetingVisibilityName === 'Public' ? 'bg-green-200 text-green-800' : department.meetingVisibilityName === 'Closed' ? 'bg-red-200 text-red-800' : 'bg-orange-200 text-orange-800'
              },
              department.meetingVisibilityName
              )
            ])
          ]),
          m(Avatar, { name: department.departmentName })
        ])
      ])
    }
  }
}

export const ViewVenue = ({ attrs: { venueId, tab, updated } }) => {
  let venue
  let contacts
  let departments

  const metaItems = []
  getVenue(venueId).then(data => {
    venue = data
    const addressArray = []
    if (venue.townCity) { addressArray.push(venue.townCity) }
    if (venue.postCode) { addressArray.push(venue.postCode) }
    if (venue.country) { addressArray.push(venue.country.code.replace('GB', 'UK')) }
    metaItems.push({
      icon: locationIcon,
      label: addressArray.join(', ')
    })
    metaItems.push({
      icon: phoneIcon,
      label: venue.telephone
    })
    metaItems.push({
      icon: globeIcon,
      label: venue.website,
      href: venue.website
    })
    metaItems.push({
      icon: emailIcon,
      label: venue.billingEmail ? `${venue.billingContactName ? venue.billingContactName : ''} <${venue.billingEmail}>` : 'No billing contact set'
    })
  })

  if (!tab) {
    tab = 'Contacts'
  }
  if (tab === 'Departments') {
    getDepartments(venueId).then(data => {
      departments = data
    })
  } else if (tab === 'Contacts') {
    getContacts(venueId).then(data => {
      contacts = data
    })
  }

  const tabs = ['Contacts', t('app', 'accommodation'), t('app', 'meetings'), 'Departments'].map((t, ix) => {
    return {
      id: ix + 1,
      name: t,
      active: tab && tab === t
    }
  })

  return {
    oncreate ({ attrs: { venueId, tab } }) {
      if (tab === 'Departments') {
        getDepartments(venueId).then(data => {
          departments = data
        })
      } else if (tab === 'Contacts') {
        getContacts(venueId).then(data => {
          contacts = data
        })
      }
    },
    view ({ attrs: { venueId, tab } }) {
      return [
        m('.pr-8', [
          venue && m(Breadcrumb, {
            links: [{
              title: 'Venues',
              href: '/venues'
            }, {
              title: venue.name
            }]
          }),
          venue && m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', [
            m('.p-4.w-full', [
              m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
                m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
                  m(PageHeading, {
                    heading: [venue.name, m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                      class: venue.isActive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
                    },
                    venue.isActive ? 'Active' : 'Closed'
                    )],
                    subtitle: venue.venueGroup ? venue.venueGroup.label : '',
                    metaItems,
                    actions: [
                      {
                        icon: editIcon,
                        label: 'Edit Venue',
                        action: () => {
                          m.route.set(`/venues/${venueId}/edit`)
                        }
                      },
                      {
                        icon: mergeIcon,
                        label: 'Merge',
                        action: () => {

                        }
                      },
                      {
                        icon: deleteIcon,
                        label: 'Delete',
                        action: () => {

                        }
                      }
                    ]
                  })
                ])
              ]),
              m('div.bg-gray-50.px-4.py-5.space-y-6.sm:p-6', [
                m(Tabs, {
                  tabs,
                  onTabChange: (tabId) => {
                    tabs.forEach(t => {
                      t.active = false
                      if (t.id === tabId) {
                        t.active = true
                        tab = t
                      }
                    })

                    if (tab.name === 'Departments') {
                      getDepartments(venueId).then(data => {
                        departments = data
                      })
                    } else if (tab.name === 'Contacts') {
                      getContacts(venueId).then(data => {
                        contacts = data
                      })
                    }

                    m.redraw()

                    var pageUrl = `/venues/${venueId}?tab=${tab.name}`
                    window.history.pushState('', '', pageUrl)
                  }
                }),
                m('.sm:grid.sm:grid-cols-4.sm:gap-4.p-4.w-full', [
                  m('div.sm:col-span-4', [
                    tabs.find(t => t.active).id === 1
                      ? [m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-3', [
                        contacts && contacts.length ? contacts.map(contact => {
                          return m('.col-span-1', [
                            m(ContactCard, { venue, contact })
                          ])
                        }) : m(Alert, { title: 'No contacts' }, [
                          m('p', 'There are no contacts for this venue, please add a contact')
                        ])
                      ]),
                      m('.flex.justify-end', [
                        m(Button, {
                          size: 'sm',
                          classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                          type: 'button',
                          onclick: () => {
                            m.route.set(`/venues/${venue.id}/contacts/create`)
                          }
                        }, [m(Svg, {
                          classes: ['w-4', 'mx-auto']
                        }, [addIcon]), 'Add contact'])
                      ])]
                      : tabs.find(t => t.active).id === 2
                        ? m('.flex', [
                          venue.isAccommodation ? m(DescriptionList, {
                            items: [{
                              label: 'Website',
                              value: m('span', venue.accommodation ? venue.accommodation.website : '')
                            }, {
                              label: 'Check-in',
                              value: m('span', venue.accommodation ? venue.accommodation.checkIn : '')
                            }, {
                              label: 'Check-out',
                              value: m('span', venue.accommodation ? venue.accommodation.checkOut : '')
                            }, {
                              label: 'Cancellation Policy',
                              value: m('span', venue.accommodation ? venue.accommodation.cancellationPolicy : '')
                            }, {
                              label: 'Reservations Contact',
                              value: m('span', venue.accommodation && venue.accommodation.reservationsContact ? `${venue.accommodation.reservationsContact.firstName} ${venue.accommodation.reservationsContact.lastName} - ${venue.accommodation.reservationsContact.telephone || ''}` : '')
                            }]
                          }) : m(Alert, { title: 'Accommodation not enabled' }, [
                            m('p', 'Please edit the venue to enable accommodation capabilities')
                          ])
                        ])
                        : tabs.find(t => t.active).id === 3
                          ? m('.flex', [
                            venue.isMeetings ? m(DescriptionList, {
                              items: [{
                                label: 'Website',
                                value: m('span', venue.meetings ? venue.meetings.website : '')
                              }, {
                                label: 'Max Occupancy',
                                value: m('span', venue.meetings ? venue.meetings.maxOccupancy : '')
                              }, {
                                label: 'Cancellation Policy',
                                value: m('span', venue.meetings ? venue.meetings.cancellationPolicy : '')
                              }, {
                                label: 'Meetings Contact',
                                value: m('span', venue.meetings && venue.meetings.conferenceOfficeContact ? `${venue.meetings.conferenceOfficeContact.firstName} ${venue.meetings.conferenceOfficeContact.lastName} - ${venue.meetings.conferenceOfficeContact.telephone || ''}` : '')
                              }]
                            }) : m(Alert, { title: 'Meetings not enabled' }, [
                              m('p', 'Please edit the venue to enable meetings capabilities')
                            ])
                          ])
                          : tabs.find(t => t.active).id === 4
                            ? [
                              m('div', [
                                departments && departments.length ? m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-3', [
                                  departments.map(department => {
                                    return m('.col-span-1', [
                                      m(DepartmentCard, { venue, department })
                                    ])
                                  })
                                ])
                                  : m(Alert, { title: 'No departments' }, [
                                    m('p', 'No departments have this venue assigned')
                                  ]),
                                m('.flex.justify-end', [
                                  m(Button, {
                                    size: 'sm',
                                    classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                                    type: 'button',
                                    onclick: () => {
                                      m.route.set(`/venues/${venue.id}/departments/create`)
                                    }
                                  }, [
                                    m(Svg, {
                                      classes: ['w-4', 'mx-auto']
                                    }, [addIcon]), 'Add department'])
                                ])
                              ])
                            ] : ''
                  ])
                ])
              ])
            ])
          ]),
          m(PastActivityBar, {
            title: t('viewVenue', 'activityTitle'),
            request: (data) => {
              getVenueActivity(venueId).then(res => {
                data(res)
              })
            }
          })
        ])
      ]
    }
  }
}
