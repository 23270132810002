
import { Input, Dialog2, TinyMce } from 'components'
import { getInvoiceEmail } from 'api/invoices'

export const EmailInvoiceDialog = ({ attrs: { invoice, loading, emailToAddresses } }) => {
  loading = false
  let editor

  const formData = {
    subject: '',
    emailBody: '',
    templateType: 0,
    emailToAddresses: emailToAddresses
  }

  if (!formData.emailToAddresses) {
    formData.emailToAddresses = [];
    if (invoice.departmentVenue.venue.billingEmail) {
      formData.emailToAddresses.push(invoice.departmentVenue.venue.billingEmail);
    }
    if (invoice.departmentVenue.venue.additionalBillingEmails) {
      invoice.departmentVenue.venue.additionalBillingEmails.split(',').forEach(email => {
        formData.emailToAddresses.push(email.trim());
      });
    }
  }

  getInvoiceEmail(invoice.id).then(data => {
    formData.subject = data.subject
    formData.templateType = data.TemplateType
    const value = data.emailBody
    if (value) {
      editor.setContent(value)
      formData.emailBody = value
    }
  })

  return {
    view ({ attrs: { loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          onsubmit(formData)
        },
        classes: ['lg:max-w-3xl', 'xl:max-w-4xl'],
        heading: 'Email Invoice',
        saving: loading,
        saveLabel: 'Send Email'
      }, [
        m('.flex.flex-col.gap-3', [
          formData && [
            m(Input, {
              label: 'To',
              value: formData.emailToAddresses.length ? formData.emailToAddresses.join(',') : '',
              required: true,
              oninput (value) {
                formData.emailToAddresses = value.split(',')
              }
            }),
            m(Input, {
              label: 'Subject',
              value: formData.subject,
              required: true,
              oninput (value) {
                formData.subject = value
              }
            }),
            m('.flex.flex-col', [
              m(TinyMce, {
                value: formData.emailBody,
                label: 'Body',
                height: 300,
                onchange: (content) => {
                  formData.emailBody = content
                },
                editor: (instance) => {
                  editor = instance
                }
              })
            ])
          ]

        ])
      ])
    }
  }
}
