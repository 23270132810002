import { Heading, PageHeading, Breadcrumb, Svg, Link } from 'components'
import { Activity } from 'components/PastActivityBar/Activity'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { DepartmentVenueRateDialog } from './DepartmentVenueRateDialog'
import { EditBookingRateDialog } from './EditBookingRateDialog'
import { EmailDialog } from './EmailDialog'
import { NoteDialog } from './NoteDialog'
import { UpdateStatusDialog } from './UpdateStatusDialog'
import { EditRefsDialog } from './EditRefsDialog'
import { EditFeesDialog } from './EditFeesDialog'
import { EditPONumberDialog } from './EditPONumberDialog'
import { EditBillingInstructionsDialog } from './EditBillingInstructionsDialog'
import { VisibilityBadge, BookingStatusBanner, TrueFalseBadge } from 'components/Badge'
import { EmptyState } from 'components/EmptyState'
import { LoadingFullScreen } from 'components/Loading/FullScreen'
import { DateTime } from 'luxon'
import { getBooking, getBookingActivity, setDepartmentVenueRate, getBookingEmailTemplates, sendEmail, updateBooking, updateBookingRate, addBookingNote, getStatuses, updateBookingStatus, copyBooking } from 'api/bookings'
import { getDepartment, getDepartmentVenues } from 'api/clients'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import clientsIcon from 'assets/icons/outline/briefcase.svg'
import departmentsIcon from 'assets/icons/outline/collection.svg'
import phoneIcon from 'assets/icons/solid/phone.svg'
import emailIcon from 'assets/icons/outline/mail.svg'
import faxIcon from 'assets/icons/outline/printer.svg'
import locationIcon from 'assets/icons/solid/location-marker.svg'
import venuesIcon from 'assets/icons/outline/office-building.svg'
import globeIcon from 'assets/icons/solid/globe.svg'
import notesIcon from 'assets/icons/outline/information-circle.svg'
import addNoteIcon from 'assets/icons/outline/annotation.svg'
import copyIcon from 'assets/icons/outline/duplicate.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'
import statusIcon from 'assets/icons/outline/fast-forward.svg'
import moreIcon from 'assets/more.svg'
import alertIcon from 'assets/alert-circle.svg'
import commissionIcon from 'assets/icons/outline/chart-pie.svg'
import exLinkIcon from 'assets/icons/solid/external-link.svg'
import cashIcon from 'assets/icons/outline/cash.svg'
import resCodeIcon from 'assets/icons/outline/terminal.svg'
import poIcon from 'assets/icons/outline/hashtag.svg'
import { notification } from 'utils/notifications'
import { nl2br, formatMoney, formatCommission, timeUntilEmailSent } from 'utils'
import { EmailMessageDialog } from 'pages/Emails/EmailMessageDialog'
import { cancelEmailMessage } from 'api/system'

export const ViewBooking = ({ attrs: { clientId, departmentId, bookingId, tab } }) => {
  let ready
  let booking
  let bookingRate
  let currency
  let department
  let departmentVenues
  let emailTemplates
  let statuses
  let bookingActivity
  let newStatus
  let templateType
  let sendEmailOnUpdate = false

  let savingDepartmentVenueRate
  const departmentVenueRateOpen = flyd.stream(false)

  let savingBookingRate
  let selectedBookingRate
  const editBookingRateOpen = flyd.stream(false)

  let savingEmail
  const emailOpen = flyd.stream(false)

  let addingNote
  const addNoteOpen = flyd.stream(false)

  let updatingStatus
  const updateStatusOpen = flyd.stream(false)

  let savingRefs
  const editRefsOpen = flyd.stream(false)

  let savingPONumber
  const editPONumberOpen = flyd.stream(false)

  let savingBillingInstructions
  const editBillingInstructionsOpen = flyd.stream(false)

  let savingFees
  const editFeesOpen = flyd.stream(false)

  const metaItems = []
  Promise.all([
    getDepartment(clientId, departmentId),
    getBooking(bookingId),
    getBookingEmailTemplates(bookingId),
    getDepartmentVenues(departmentId, { pagesize: 2000 }),
    getStatuses()
  ]).then(([d, b, et, dv, s]) => {
    department = d
    booking = b
    departmentVenues = dv.data
    emailTemplates = et
    statuses = s
    const addressArray = []
    if (booking.addressLine1) { addressArray.push(booking.addressLine1) }
    if (booking.townCity) { addressArray.push(booking.townCity) }
    if (booking.postCode) { addressArray.push(booking.postCode) }
    if (booking.country) { addressArray.push(booking.country.code.replace('GB', 'UK')) }
    metaItems.push({
      icon: locationIcon,
      label: addressArray.join(', ')
    })
    metaItems.push({
      icon: phoneIcon,
      label: booking.telephone
    })
    metaItems.push({
      icon: emailIcon,
      label: booking.email
    })

    initBookingRate()
    currency = booking.departmentVenue && booking.departmentVenue.venue.currency ? booking.departmentVenue.venue.currency.symbol : '£'
    ready = true
  })

  const initBookingRate = () => {
    if (booking.bookingRateId && booking.bookingRates) {
      bookingRate = booking.bookingRates.find(br => {
        return br.id === booking.bookingRateId
      })
    }
  }

  const setVenueRate = () => {
    if (booking.departmentVenueRateId) {
      savingDepartmentVenueRate = true
      setDepartmentVenueRate(booking.id, booking.departmentVenueRateId).then(data => {
        booking = data
        savingDepartmentVenueRate = false
        initBookingRate()
        currency = booking.departmentVenue && booking.departmentVenue.venue.currency ? booking.departmentVenue.venue.currency.symbol : '£'
        departmentVenueRateOpen(false)
      }).catch(err => {
        notification.error({ title: err.response })
        savingDepartmentVenueRate = false
      })
    }
  }

  const saveBookingRate = () => {
    savingBookingRate = true
    const updateRate = {
      id: selectedBookingRate.id,
      tariff: selectedBookingRate.tariff,
      label: selectedBookingRate.label
    }
    updateBookingRate(booking.id, selectedBookingRate.id, updateRate).then(data => {
      selectedBookingRate = data
      bookingRate = data
      savingBookingRate = false
      editBookingRateOpen(false)
      updateTheBooking()
    }).catch(err => {
      notification.error({ title: err.response })
      savingBookingRate = false
    })
  }

  const sendBookingEmail = (email) => {
    savingEmail = true
    sendEmail(booking.id, email).then(data => {
      emailOpen(false)
      savingEmail = false

      getBookingActivity(bookingId).then(data => {
        bookingActivity = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
      savingEmail = false
    })
  }

  const updateStatus = (request) => {
    updatingStatus = true
    updateBookingStatus(booking.id, request).then(data => {
      booking = data
      updateStatusOpen(false)
      updatingStatus = false

      getBookingActivity(bookingId).then(data => {
        bookingActivity = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
      updatingStatus = false
    })
  }

  const updateTheBooking = () => {
    savingPONumber = true
    savingFees = true
    savingRefs = true
    savingBillingInstructions = true
    updateBooking(booking.id, booking).then(data => {
      booking = data
      savingFees = false
      savingPONumber = false
      savingRefs = false
      savingBillingInstructions = false
      editRefsOpen(false)
      editFeesOpen(false)
      editPONumberOpen(false)
      editBillingInstructionsOpen(false)

      getBookingActivity(bookingId).then(data => {
        bookingActivity = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
      savingFees = false
      savingPONumber = false
      savingRefs = false
      savingBillingInstructions = false
    })
  }

  getBookingActivity(bookingId).then(data => {
    bookingActivity = data.data
  })

  let activityOpen = true

  const addNote = (note) => {
    addingNote = true
    addBookingNote(booking.id, note).then(data => {
      bookingActivity = data.data
      addNoteOpen(false)
      addingNote = false
    })
  }

  const copyTheBooking = () => {
    copyBooking(bookingId).then(data => {
      m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/${data.id}/copy`)
    })
  }

  let currentEmail = {}
  const emailMessageOpen = flyd.stream(false)
  let cancelEmailConfirm = false

  const cancelEmail = (emailMessageId) => {
    cancelEmailConfirm = false
    cancelEmailMessage(emailMessageId).then(() => {
      getBookingActivity(bookingId).then(data => {
        bookingActivity = data.data
      })
    })
  }

  return {
    view ({ attrs: { bookingId, clientId, departmentId, tab } }) {
      return [
        ready && m('div', {
          class: activityOpen ? 'pr-64 xl:pr-72 xxl:pr-96' : 'pr-8'
        }, [
          m(Breadcrumb, {
            links: [{
              title: department.name,
              href: `/clients/${clientId}/departments/${departmentId}`
            }, {
              title: 'Bookings',
              href: `/clients/${clientId}/departments/${departmentId}?tab=Bookings`
            }, {
              title: `${booking.name}: ${DateTime.fromISO(booking.arrivalDate).toLocaleString()}`
            }]
          }),
          m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', {
            class: booking.statusName === 'Confirmed' ? 'bg-green-100'
              : booking.statusName === 'Cancelled' ? 'bg-red-100'
                : 'bg-gray-100'
          }, [
            m('.p-4.w-full', [
              m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', {
              }, [
                m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
                  m(PageHeading, {
                    heading: [booking.name],
                    subtitle: [m(Svg, { classes: ['w-5', 'h-5'] }, clientsIcon), m(Link, { href: `/clients/${department.clientId}` }, department.clientName), m(Svg, { classes: ['w-5', 'h-5', 'ml-3'] }, departmentsIcon), m(Link, { href: `/clients/${department.clientId}/departments/${department.id}` }, department.name)],
                    metaItems,
                    actions: [
                      bookingRate && bookingRate.departmentVenueRate && booking.statusId === 2 && booking.reservationCode && {
                        icon: emailIcon,
                        label: 'Send Booking Confirmation',
                        action: () => {
                          newStatus = 5
                          templateType = 3
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      },
                      bookingRate && bookingRate.departmentVenueRate && booking.statusId < 3 && {
                        icon: emailIcon,
                        label: 'Send Venue Booking Request',
                        action: () => {
                          newStatus = 2
                          templateType = 2
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      },
                      bookingRate && bookingRate.departmentVenueRate && {
                        icon: emailIcon,
                        label: 'Send PO Request',
                        action: () => {
                          newStatus = 7
                          templateType = 7
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      },
                      {
                        icon: emailIcon,
                        label: 'Send Email',
                        action: () => {
                          templateType = null
                          emailOpen(true)
                        }
                      },
                      {
                        icon: statusIcon,
                        label: 'Update Status',
                        action: () => {
                          newStatus = booking.statusId
                          templateType = null
                          sendEmailOnUpdate = false
                          updateStatusOpen(true)
                        }
                      },
                      {
                        icon: resCodeIcon,
                        label: 'Set Reservation Code',
                        action: () => {
                          editRefsOpen(true)
                        }
                      },
                      {
                        icon: poIcon,
                        label: 'Set PO Number',
                        action: () => {
                          editPONumberOpen(true)
                        }
                      },
                      {
                        icon: cashIcon,
                        label: 'Set Commission Fees',
                        action: () => {
                          editFeesOpen(true)
                        }
                      },
                      {
                        icon: editIcon,
                        label: 'Edit Billing Instructions',
                        action: () => {
                          editBillingInstructionsOpen(true)
                        }
                      },
                      {
                        icon: editIcon,
                        label: 'Edit Delegate Request',
                        action: () => {
                          m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/${bookingId}/edit`)
                        }
                      },
                      {
                        icon: copyIcon,
                        label: 'Clone Booking',
                        action: () => {
                          copyTheBooking()
                        }
                      },
                      booking.statusId !== 15 && booking.statusId !== 20 && {
                        icon: cancelIcon,
                        label: 'Cancel Booking',
                        color: 'text-red-800',
                        action: () => {
                          newStatus = 15
                          templateType = 5
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      }
                    ],
                    timestamps: [
                      {
                        title: 'Created',
                        value: booking.createdDate
                      },
                      {
                        title: 'Last Action',
                        value: booking.lastActionDate
                      }
                    ]
                  })
                ]),
                m(BookingStatusBanner, { status: booking.statusName, size: 'xl' }),
                m('div.px-4.py-5.sm:p-6', {
                  class: 'bg-gray-50'
                }, [
                  m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-6', [
                    booking && booking.invoiceId
                      ? m('.sm:col-span-2.lg:col-span-6', [
                        m(Card, m('.space-y-6.divide-y.divide-gray-200', [
                          m('.flex.w-full', [
                            m('.flex-2', [
                              m('h5.text-sm.text-gray-500', 'Invoice'),
                              m('h3.text-lg.xl:text-xl', m(Link, { href: `/invoices/${booking.invoiceId}` }, `${booking.invoiceNumber} - ${DateTime.fromISO(booking.invoiceDate).toLocaleString(DateTime.DATE_FULL)}`))
                            ])
                          ])
                        ]))
                      ]) : null,
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, m('.space-y-6.divide-y.divide-gray-200', [
                        m('.flex.w-full', [
                          m('.flex-2', [
                            m('h5.text-sm.text-gray-500', 'Arrival'),
                            m('h3.text-lg.xl:text-xl', DateTime.fromISO(booking.arrivalDate).toLocaleString(DateTime.DATE_HUGE))
                          ]),
                          m('.flex-1', [
                            m('h5.text-sm.text-gray-500', 'Duration'),
                            m('h4.xl:text-lg', `${booking.nights} Night${(booking.nights > 1 ? 's' : '')}`)
                          ])

                        ])
                      ]))
                    ]),
                    m('div.lg:col-span-3.xl:col-span-4.space-y-6', [
                      m(Card, {
                        buttons: [
                          m(Button, {
                            type: 'button',
                            size: 'sm',
                            variant: booking.departmentVenue ? 'plain' : 'info',
                            icon: venuesIcon,
                            onclick () {
                              departmentVenueRateOpen(true)
                            }
                          }, booking.departmentVenue ? 'Change Venue / Rate' : 'Set Venue'),
                          booking.departmentVenue && booking.bookingType === 2 && m(Button, {
                            type: 'button',
                            disabled: true,
                            onclick () {
                              m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/${bookingId}/rates/create`)
                            }
                          }, 'Add Rate')
                        ]
                      }, m('.space-y-6.divide-y.divide-gray-200', [
                        booking.departmentVenue && booking.departmentVenue.venue ? m('div.space-y-1', [
                          m('h5.text-sm.text-gray-500', 'Selected Venue'),
                          m('.flex.gap-3', [
                            m(Svg, { classes: ['w-8', 'h-8'] }, venuesIcon),
                            m('h3.text-2xl', m(Link, { href: `/venues/${booking.departmentVenue.venue.id}` }, booking.departmentVenue.venue.name))
                          ]),
                          m('.flex.gap-x-2.text-gray-600.flex-wrap.leading-tight', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, locationIcon),
                            booking.departmentVenue.venue.addressLine1 && m('span', booking.departmentVenue.venue.addressLine1 + ','),
                            booking.departmentVenue.venue.townCity && m('span', booking.departmentVenue.venue.townCity + ','),
                            booking.departmentVenue.venue.county && m('span', booking.departmentVenue.venue.county + ','),
                            booking.departmentVenue.venue.postCode && m('span', booking.departmentVenue.venue.postCode),
                            booking.departmentVenue.venue.country && m('span', booking.departmentVenue.venue.country.code)
                          ]),
                          m('.flex.gap-2.text-gray-500.text-sm.flex-wrap.leading-tight', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, phoneIcon),
                            booking.departmentVenue.venue.telephone,
                            booking.departmentVenue.venue.fax && m(Svg, { classes: ['w-4', 'h-4', 'ml-3'] }, faxIcon),
                            booking.departmentVenue.venue.fax && booking.departmentVenue.venue.fax,
                            booking.departmentVenue.venue.website && m(Svg, { classes: ['w-4', 'h-4', 'ml-3'] }, globeIcon),
                            booking.departmentVenue.venue.website && m('a.hover:underline', {
                              href: booking.departmentVenue.venue.website,
                              options: { target: '_blank' }
                            }, [
                              'Website',
                              m(Svg, { classes: ['w-4', 'h-4', 'inline', 'ml-1'] }, exLinkIcon)
                            ])
                          ]),
                          m('.flex.gap-2.text-gray-500.text-sm.leading-tight', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, commissionIcon),
                            m('p', booking.isCommissionFree ? [m('span.line-through.mr-2.text-red-600', formatCommission(booking.departmentVenue.commission)), `Commission free: ${booking.commissionFreeNotes}`] : formatCommission(booking.departmentVenue.commission))
                          ]),
                          booking.departmentVenue.venue.notes && m('.flex.gap-2.text-gray-600.pt-3.text-sm', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, notesIcon),
                            m('span', booking.departmentVenue.venue.notes)
                          ])

                        ]) : m('div.space-y-1', [
                          m('h5.text-sm.text-gray-500', 'No venue & rate selected')
                        ]),
                        bookingRate && m('div.space-y-1.pt-3', [
                          m('h5.text-sm.text-gray-500', 'Rates'),
                          m('.flex.gap-3.justify-between', [
                            m('p.text-lg', [bookingRate.label,
                              bookingRate.isFlatRate && m('span.ml-3.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium.bg-purple-400.text-white', 'Flat Rate')
                            ]),
                            m('p.text-lg', formatMoney(bookingRate.tariff, currency))
                          ]),
                          bookingRate.departmentVenueRate && m('.flex.gap-3.justify-between.flex-wrap', [
                            m('p.text-xs.text-gray-400', ['Current Venue Rate: ', bookingRate.departmentVenueRate.label,
                              bookingRate.departmentVenueRate.isFlatRate && m('span.ml-3.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium.bg-purple-400.text-white', 'Flat Rate'),
                              m(VisibilityBadge, { visibility: bookingRate.departmentVenueRate.visibilityName, size: 'xs' })
                            ]),
                            m('p.text-xs.text-gray-400', formatMoney(bookingRate.departmentVenueRate.tariff, currency)),

                            m(Button, {
                              type: 'button',
                              size: 'xs',
                              icon: venuesIcon,
                              onclick () {
                                selectedBookingRate = bookingRate
                                editBookingRateOpen(true)
                              }
                            }, 'Edit Rate')
                          ])
                        ]),
                        m('div.space-y-1.pt-3', [
                          m('h5.text-sm.text-gray-500', 'Total Tariff'),
                          m('p.text-xl', formatMoney(booking.totalTariff, currency, 2))

                        ]),
                        booking.departmentVenue && booking.departmentVenue.venue.accommodation && booking.departmentVenue.venue.accommodation.cancellationPolicy && m('div.space-y-1.pt-3', [
                          m('h5.text-sm.text-gray-500', 'Cancellation Policy'),
                          m('p', booking.departmentVenue.venue.accommodation.cancellationPolicy)
                        ])
                      ])),
                      booking.additionalInfo && m(Card, m('.space-y-6.divide-y.divide-gray-200', [
                        m('div', [
                          m('h5.text-sm.text-gray-500', 'Additional Info'),
                          m('p', m.trust(nl2br(booking.additionalInfo)))

                        ])
                      ]))
                    ]),
                    m('div.lg:col-span-3.xl:col-span-2.space-y-6', [
                      m(Card, m('.space-y-6.divide-y.divide-gray-200', [
                        booking.statusId === 15 && m('.flex.justify-between.items-center', [
                          m('h5.text-sm.text-gray-500', 'Cancellation Fee Payable?'),
                          m(TrueFalseBadge, { trueFalse: booking.isCancellationFeePayable })
                        ]),
                        m(booking.statusId === 15 ? '.pt-6' : 'div', [
                          m('h5.text-sm.text-gray-500', 'Reservation Code'),
                          booking.reservationCode ? m('p.text-lg', booking.reservationCode) : m('p.text-gray-400.uppercase.text-xs', m('p.text-orange-400.uppercase.text-xs', 'Not set')),
                          (booking.isPONumberRequired || booking.poNumber) && m('h5.text-sm.text-gray-500.mt-2', 'PO Number'),
                          (booking.isPONumberRequired || booking.poNumber) && (booking.poNumber ? m('p.text-lg', booking.poNumber) : m('p.text-orange-400.uppercase.text-xs', 'Not set'))
                        ]),
                        booking.bookingFields && booking.bookingFields.length ? m('.pt-6.space-y-2', [
                          booking.bookingFields.map(bf => {
                            return m(bf.departmentBookingField.type === 1 ? '.flex.justify-between.items-center' : 'div', [
                              m('h5.text-sm.text-gray-500', bf.departmentBookingField.label),
                              bf.departmentBookingField.type === 1 ? m(TrueFalseBadge, { trueFalse: bf.value }) : m('p', bf.value)
                            ])
                          })
                        ]) : '',
                        m('.pt-6', [
                          m('h5.text-sm.text-gray-500', 'Notes'),
                          m('p', booking.notes && m.trust(booking.notes.replaceAll('\n', '<br>')))

                        ])
                      ])),
                      m(Card, m('div.space-y-2', [
                        m('h5.text-sm.text-gray-500', 'Delegate Venue Preferences'),
                        booking.venuePreferences && booking.venuePreferences.length ? m('.mt-2.grid.gap-3', [
                          booking.venuePreferences.map(vp => {
                            return m('div.bg-gray-100.p-2.flex', [
                              m('.h-6.w-6.mr-2.text-lg.rounded-full.text-center.text-white.bg-gray-500.leading-snug', {
                                style: 'min-width:1.5rem'
                              }, vp.preference),
                              m('div', [
                                m('p.text-sm', vp.venue.name),
                                m('p.text-xs', vp.departmentVenueRate.label),
                                m('p.text-xs', formatMoney(vp.departmentVenueRate.tariff, vp.venue.currency.symbol))
                              ])
                            ])
                          })
                        ]) : m('p', 'No preferences set'),
                        booking.preferenceInfo && m('div', [
                          m('h5.text-xs.text-gray-500', 'Preference Details'),
                          m('p', booking.preferenceInfo)
                        ])
                      ]))
                    ])

                  ])
                ])
              ])
            ])
          ])
        ]),
        m('.fixed.right-0.bottom-0.z-30.bg-white.flex.shadow-lg.activity-initial', {
          style: 'top: 63px',
          class: activityOpen ? 'w-64 xl:w-72 xxl:w-96 activity-slide-in' : 'w-8 activity-slide-in'
        }, [
          m('.w-8.flex.h-100.items-center.justify-center.cursor-pointer.flex-shrink-0.border-r', {
            onclick () {
              activityOpen = !activityOpen
            }
          }, [
            m(Svg, {
              classes: ['w-5', 'transform', 'rotate-90']
            }, moreIcon)
          ]),
          activityOpen && m('.w-full.flex.flex-col', [
            m('.flex.justify-between.items-end.p-3', [
              m(Heading, { level: 3 }, 'Activity'),
              m(Button, {
                type: 'button',
                size: 'xs',
                icon: addNoteIcon,
                onclick () {
                  addNoteOpen(true)
                }
              }, 'Add Note')
            ]),
            bookingActivity
              ? bookingActivity.length
                ? m('.flex.flex-col.border-t.h-full.overflow-auto', bookingActivity.map(ea => {
                  return [
                    ea.emailMessage && m('div.p-1.flex.justify-start.items-center', {
                      class: ea.emailMessage.emailMessageStatusId === 0 ? 'bg-orange-300 text-orange-800' : ea.emailMessage.emailMessageStatusId === 1 ? 'bg-green-300 text-green-800' : 'bg-red-300 text-red-800'
                    }, [
                      m('button.hover:text-black', {
                        type: 'button',
                        onclick () {
                          currentEmail = ea.emailMessage
                          emailMessageOpen(true)
                        }
                      }, m(Svg, { classes: ['w-6', 'h-6', 'mx-1'] }, emailIcon)
                      ),
                      m('span.text-sm', ea.emailMessage.statusName),
                      ea.emailMessage.emailMessageStatusId !== 3 && m('span.text-xs.flex-grow.text-right', [
                        ea.emailMessage.sentDate && DateTime.fromISO(ea.emailMessage.sentDate).toLocaleString(DateTime.DATETIME_MED),
                        !ea.emailMessage.sentDate && timeUntilEmailSent(ea.emailMessage.createdDate, ea.emailMessage.delay * 60)
                      ]),
                      ea.emailMessage.emailMessageStatusId === 0 && m('button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200', {
                        type: 'button',
                        onclick () {
                          if (cancelEmailConfirm) { cancelEmail(ea.emailMessage.id) } else {
                            cancelEmailConfirm = true
                            setTimeout(() => {
                              cancelEmailConfirm = false
                              m.redraw()
                            }, 3000)
                          }
                        }
                      }, [
                        m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
                        cancelEmailConfirm && m('span.ml-1.px-1', 'Click again to confirm')
                      ]
                      )
                    ]),
                    ea.isModelUpdate ? '' : m(Activity, { activity: ea })]
                }))
                : m(EmptyState, { icon: alertIcon, message: t('activityBar', 'noActivity') })
              : m(LoadingFullScreen)
          ])
        ]),
        departmentVenueRateOpen() &&
          m(DepartmentVenueRateDialog, {
            open: departmentVenueRateOpen,
            onsubmit: setVenueRate,
            loading: savingDepartmentVenueRate,
            booking,
            venues: departmentVenues
          }),
        editBookingRateOpen() &&
          m(EditBookingRateDialog, {
            open: editBookingRateOpen,
            onsubmit: saveBookingRate,
            loading: savingBookingRate,
            booking,
            bookingRate: selectedBookingRate
          }),
        emailOpen() &&
          m(EmailDialog, {
            open: emailOpen,
            onsubmit: sendBookingEmail,
            loading: savingEmail,
            booking,
            templates: emailTemplates,
            templateType: templateType
          }),
        updateStatusOpen() &&
          m(UpdateStatusDialog, {
            open: updateStatusOpen,
            onsubmit: updateStatus,
            loading: updatingStatus,
            booking,
            templates: emailTemplates,
            statuses,
            statusId: newStatus,
            templateType: templateType,
            sendEmail: sendEmailOnUpdate
          }),
        addNoteOpen() &&
          m(NoteDialog, {
            open: addNoteOpen,
            onsubmit: addNote,
            loading: addingNote,
            booking,
            templates: emailTemplates
          }),
        editRefsOpen() &&
          m(EditRefsDialog, {
            open: editRefsOpen,
            onsubmit: updateTheBooking,
            loading: savingRefs,
            booking
          }),
        editPONumberOpen() &&
          m(EditPONumberDialog, {
            open: editPONumberOpen,
            onsubmit: updateTheBooking,
            loading: savingPONumber,
            booking
          }),
        editBillingInstructionsOpen() &&
          m(EditBillingInstructionsDialog, {
            open: editBillingInstructionsOpen,
            onsubmit: updateTheBooking,
            loading: savingBillingInstructions,
            booking
          }),
        editFeesOpen() &&
          m(EditFeesDialog, {
            open: editFeesOpen,
            onsubmit: updateTheBooking,
            loading: savingFees,
            booking
          }),
        emailMessageOpen() &&
          m(EmailMessageDialog, {
            open: emailMessageOpen,
            email: currentEmail
          })
      ]
    }
  }
}
